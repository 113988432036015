
  import userController from '/home/builduser/work/658b46cc31624e53/packages/comp-gallery-app/src/components/Widget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://4b09d700a9fe469db256b8cda73d753b@sentry.wixpress.com/5145',
    id: '4b09d700a9fe469db256b8cda73d753b',
    projectName: 'comp-gallery-app',
    teamName: 'pro-gallery',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/658b46cc31624e53/packages/comp-gallery-app/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/658b46cc31624e53/packages/comp-gallery-app/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "comp-gallery",
    componentName: "Widget",
    appDefinitionId: "71a5b69a-959f-4d11-944e-42aeaa5c5452",
    componentId: "65588ae3-8352-4ef8-94a2-c486701649fb",
    projectName: "comp-gallery-app",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/658b46cc31624e53/packages/comp-gallery-app/src/components/Widget/controller.ts",
  };

  export const controller = _controller
  export default controller;
